<template>
  <div id="who-we-are">
    <Hero :slideContent="heroSlide" />

    <div class="mt-10 max-w-screen-lg mx-auto pb-20 w-9/10">
      <div class="text-nasida-green">
        <h3 class="text-lg md:text-2xl">Who We Are</h3>
        <h1 class="my-6 text-3xl md:text-4xl">A holistic investment agency</h1>
        <p class="text-justify text-nasida-grey">NASIDA is the Nasarawa Investment and Development Agency, created through an act of the Nasarawa State House of Assembly to initiate, promote, facilitate and coordinate investments in Nasarawa State, whether it be public private partnerships, privatization, concessions or commercialization of state owned assets.</p>
      </div>
      <!-- mukeey -->
      <div class="text-nasida-green mt-5">
        <h3 class="text-lg md:text-2xl">Data on Nasarawa State encompassing macro-economic and labor market information.</h3>
        <!-- <h3 class="text-lg md:text-2xl">Information About Nasarawa State that Include Macro-Economic and Labour Market Data</h3> -->
        <!-- <h1 class="my-6 text-3xl md:text-4xl">Information About Nasarawa State that Include Macro-Economic and Labour Market Data</h1> -->
        
        <p class="text-justify text-nasida-grey mt-3">With an area of 27,118 km square, Nasarawa State is the 15th largest state in land area and Nigeria's second least populated state with an estimated population of about 2.7 million people. The state has a GDP of $12.01 billion as of 2021, with 74.4% of the total population constituting the total labour force and about 18.7 unemployment rate.</p>
        <p class="text-justify text-nasida-grey mt-3">The State is considered one of the most peaceful and stable states in Nigeria, making it very attractive for investors. The state has an active and cheap labour force with a growing local market as it borders five (5) states of Plateau, Kaduna, Benue, Taraba, Kogi and The FCT Abuja.</p>
        <p class="text-justify text-nasida-grey mt-3">
          Business and investment opportunities are abundant (Agriculture, Mining, Oil & Gas, etc,) for overall value chain production, processing and manufacturing.
          The state has a befitting road network linking the state capital to all its 13 local governments and development areas, making it a favourable destination to crystallise investments. 
          The government has rolled out favourable regulations and investment incentives to support existing businesses and intending investment inflow.
        </p>
      </div>

      <div class="text-nasida-green mt-5">
        <h3 class="text-lg md:text-2xl mx-4">States available infrastructures</h3>

        <div class="mt-2 text-nasida-grey">
          <!-- <div class="font-bold mb-5">The unit conducts</div> -->
          <li>A dualized road linking the state to Benue and the FCT, Abuja.</li>
          <li>
            Existing rural roads linking the state's 13 Local governments and development Areas.
          </li>
          <li>
            A rail line passing from Lagos to Maiduguri through the state.
          </li>
          <li>
            A Cargo airport ready to commence operations.
          </li>
          <li>
            Two Universities, two Polytechnics, two Colleges of Higher Education, and other institutions of learning.
          </li>
          <li>
            Active television and radio stations in the state.
          </li>
        </div>
      </div>
      <!-- end mukeey -->

      <div class="my-10 md:grid md:grid-cols-3 items-center">
        <div class="bg-pale-green rounded-lg p-6 md:col-span-2 md:pr-52 md:py-20">
          <div class="w-16">
            <img src="../../assets/icons/quote-up.svg">
          </div>
          <p class="text-justify my-2">We are adequately positioned to address the fundamental issues that continue to hold back full private sector participation in key sectors of the Nasarawa State economy. We are determined to cut the red tapes and administrative bottlenecks of doing business in Nasarawa State. </p>
          <div class="w-16 ml-auto">
            <img src="../../assets/icons/quote-down.svg">
          </div>
        </div>
        <div class="w-full relative h-72 mt-6 md:-ml-40 md:h-4/5 md:w-96 holistic rounded-lg overflow-hidden">
          <div class="absolute bottom-6 left-6 text-white z-10">
            <h1 class="font-bold text-3xl mb-3">A holistic investment service provider</h1>
            <AnchorLink
              text="Learn More"
              url="departments"
              color="white"
            />
          </div>
          <img
            class="rounded-lg"
            src="../../assets/images/who-we-are.png"
          >
        </div>
      </div>
      <div class="my-10 grid grid-cols-1 gap-6 md:grid-cols-3 md:mt-20">
        <div class="card">
          <div class="card-text">
            <h1 class="font-bold text-3xl">We are a hybrid agency</h1>
            <AnchorLink
              class="link"
              text="Learn More"
              url="departments"
              color="white"
            />
          </div>
          <img
            src="../../assets/images/who-we-are-image.png"
            alt=""
          >
        </div>
        <div class="card">
          <div class="card-text">
            <h1 class="font-bold text-3xl">We do things differently</h1>
            <AnchorLink
              class="link"
              text="Learn More"
              url="infrastructure-and-thematic-holdings"
              color="white"
            />
          </div>
          <img
            src="../../assets/images/who-we-are-image.png"
            alt=""
          >
        </div>
        <div class="card">
          <div class="card-text">
            <h1 class="font-bold text-3xl">We are delivery focused</h1>
            <AnchorLink
              class="link"
              text="Learn More"
              url="factsheet"
              color="white"
            />
          </div>
          <img
            src="../../assets/images/who-we-are-image.png"
            alt=""
          >
        </div>
      </div>

    </div>
  </div>
</template>

<script src="./who-we-are.page.js"></script>
<style src="./who-we-are.page.css" scoped lang="css"></style>