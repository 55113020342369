import { defineComponent } from "vue";

import Hero from "@/components/hero/index.vue";
import AnchorLink from "@/components/anchor-link/index.vue";
import heroBg from "../../assets/images/who-we-are-bg.png";

export default defineComponent({
  name: "Who We Are",
  components: { Hero, AnchorLink },
  setup() {
    const heroSlide = [
      {
        title: "About Us",
        image: heroBg,
      },
    ];

    return {
      heroSlide,
    };
  },
});
